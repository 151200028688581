import { render, staticRenderFns } from "./userProfile.vue?vue&type=template&id=187ee36c&"
import script from "./userProfile.vue?vue&type=script&lang=js&"
export * from "./userProfile.vue?vue&type=script&lang=js&"
import style0 from "./userProfile.vue?vue&type=style&index=0&id=187ee36c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports